* {
  font-family: 'Montserrat', sans-serif;
}
body {
  background-color: #edeff2;
  font-family: 'Open Sans', sans-serif;
  color: #6a859c;
  font-size: 16px;
  line-height: 20px;
}

h1 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 38px;
  line-height: 44px;
  font-weight: 700;
}

h2 {
  margin-top: 0px;
  margin-bottom: 10px;
  color: #676770;
  font-size: 32px;
  line-height: 36px;
  font-weight: 300;
  text-align: center;
}

h3 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #676770;
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
  letter-spacing: 7px;
  text-transform: uppercase;
}

h4 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

h5 {
  margin-top: 0px;
  margin-bottom: 20px;
  color: #676770;
  font-size: 18px;
  line-height: 20px;
  font-weight: 300;
  letter-spacing: 4px;
  text-transform: uppercase;
}

h6 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
}

p {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 25px;
  font-weight: 300;
}

.logoimg {
  width: 35px;
}

@media (min-width: 991px) {
  .logoimg {
    width: 35px;
  }
}

.button {
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
  padding: 12px 30px;
  border-radius: 4px;
  background-color: #e08d06;
  -webkit-transition: background-color 300ms ease;
  transition: background-color 300ms ease;
  color: #fff;
  font-size: 16px;
  line-height: 21px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
}

.button:hover {
  background-color: #fff;
  color: #e08d06;
  border: #fff;
}

.button.w--current {
  background-color: #e08d06;
}

.button.full-width {
  display: block;
  width: 100%;
  margin-right: 0px;
  margin-left: 0px;
}

.button.tab {
  margin-right: 8px;
  margin-left: 8px;
  background-color: #92a0ad;
}

.button.tab:hover {
  background-color: #e08d06;
}

.button.tab.w--current {
  background-color: #e08d06;
}

.navigation-link {
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  color: #676770;
}

.navigation-link:hover {
  color: #e08d06;
}

.navigation-bar {
  position: fixed !important;
  width: 100%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  background-color: #fff;
}

.brand-text {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'Open Sans', sans-serif;
  color: #e08d06;
  font-size: 25px;
  line-height: 25px;
  font-weight: 300;
  letter-spacing: 4px;
  text-transform: uppercase;
}

.brand-link {
  padding-top: 16px;
  padding-bottom: 16px;
  margin: auto;
}

.section {
  position: relative;
  padding: 80px 15px;
  background-color: #fff;
  text-align: center;
}

@media (min-width: 345) {
  .section {
    padding: 80px 30px;
  }
}
.section.accent {
  background-color: #f8f7f7;
}

.white-box {
  padding: 15px;
  border: 1px solid #dcebf7;
  border-radius: 5px;
  background-color: #fff;
  text-align: center;
  min-height: 342px;
}

.white-box.transparent {
  border-style: none;
  background-color: transparent;
  min-height: 0;
  margin-bottom: 5px;
}

.hero-section {
  padding-top: 242px;
  padding-bottom: 242px;
}

.hero-section.centered {
  padding-top: 400px;
  padding-bottom: 50px;
  border-bottom: 4px solid #e08d06;
  background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(255, 255, 255, 0.061)),
      to(rgba(255, 255, 255, 0.061)))
    ),
    url('./images/heronb1.jpg');
  background-image: linear-gradient(
      180deg,
      rgba(46, 157, 255, 0.15),
      rgba(0, 0, 0, 0.22)
    ),
    url('./images/heronb1.jpg');
  background-position: 500% 50%;
  background-size: auto, cover;
  text-align: center;
}

.hero-heading {
  margin-bottom: 5px;
  color: #fff;
  font-size: 100px;
  line-height: 80px;
  font-weight: 400;
  letter-spacing: 4px;
  font-family: 'Alex Brush', cursive;
}

.hero-subheading {
  margin: 10px auto 25px auto;
  width: auto;
  color: #e08d06;
  font-size: 25px;
  background: rgb(255, 255, 255);
  line-height: 25px;
  text-align: center;
  font-weight: 300;
  letter-spacing: 4px;
  padding: 5px 15px;
  font-family: 'Montserrat', sans-serif;
}

.hollow-button {
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
  padding: 10px 30px;
  border: 1px solid #fff;
  border-radius: 4px;
  -webkit-transition: background-color 300ms ease, border 300ms ease,
    color 300ms ease;
  transition: background-color 300ms ease, border 300ms ease, color 300ms ease;
  color: #fff;
  line-height: 21px;
  font-weight: 300;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
}

.hollow-button:hover {
  border-color: #e08d06;
  color: #fff;
  background: #e08d06;
}

.hollow-button.all-caps {
  text-transform: uppercase;
}

.section-heading {
  margin-top: 0px;
  margin-bottom: 16px;
}

.section-heading.centered {
  color: #676770;
  font-size: 30px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 5px;
  text-transform: uppercase;
  margin-bottom: 35px;
}

.section-heading.centered.white {
  color: #fff;
}

.section-subheading.center {
  color: #8e8e9c;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 3px;
}

.section-subheading.center.off-white {
  padding-bottom: 0px;
  color: #e8e8e8;
  letter-spacing: 0px;
}

.section-title-group {
  margin-bottom: 60px;
}

.form-field {
  height: 45px;
  margin-bottom: 17px;
  border: 0px solid #000;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(64, 64, 71, 0.3);
}

.form-field.text-area {
  height: 110px;
}

.footer {
  padding-top: 35px;
  padding-bottom: 35px;
}

.footer.center {
  border-top: 1px solid #dbdbdb;
  background-color: #383838;
  text-align: center;
}

.footer-text {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #9e9e9e;
  font-size: 16px;
}

.grid-image {
  display: block;
  width: 85px !important;
  height: 85px !important;
  margin: 20px auto;
  border: 10px solid #fff;
  border-radius: 50%;
  background-color: #e08d06;
  box-shadow: 0 0 0 1px #e08d06;
  display: flex;
  justify-content: center;
}

.info-icon {
  float: left;
}

.footer-link {
  display: block;
  margin-bottom: 6px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d5d5e0;
  color: #668cad;
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
}

.footer-link:hover {
  color: rgba(0, 140, 255, 0.84);
}

.footer-link.with-icon {
  margin-left: 30px;
}

.tab-menu {
  margin-bottom: 40px;
  text-align: center;
}

.tabs-wrapper {
  text-align: center;
}

.fullwidth-image {
  width: 100%;
  margin-bottom: 20px;
  height: 200px;
  border-radius: 5px;
}

.white-text {
  margin-bottom: 20px;
  color: #fff;
}

.form {
  margin-top: 40px;
}
.form-field {
  height: 45px;
  margin-bottom: 17px;
  border: 0px solid #000;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(64, 64, 71, 0.3);
}

.form-field.text-area {
  height: 110px;
}

html.w-mod-js *[data-ix='fade-in-bottom-page-loads'] {
  opacity: 0;
  -webkit-transform: translate(0px, 50px);
  -ms-transform: translate(0px, 50px);
  transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix='fade-in-left-scroll-in'] {
  opacity: 0;
  -webkit-transform: translate(-50px, 0px);
  -ms-transform: translate(-50px, 0px);
  transform: translate(-50px, 0px);
}

html.w-mod-js *[data-ix='fade-in-right-scroll-in'] {
  opacity: 0;
  -webkit-transform: translate(50px, 0px);
  -ms-transform: translate(50px, 0px);
  transform: translate(50px, 0px);
}

html.w-mod-js *[data-ix='fade-in-top-scroll-in'] {
  opacity: 0;
  -webkit-transform: translate(0px, -50px);
  -ms-transform: translate(0px, -50px);
  transform: translate(0px, -50px);
}

html.w-mod-js *[data-ix='fade-in-bottom-scroll-in'] {
  opacity: 0;
  -webkit-transform: translate(0px, 50px);
  -ms-transform: translate(0px, 50px);
  transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix='bounce-in-scroll-in'] {
  opacity: 0;
  -webkit-transform: scale(0.6, 0.6);
  -ms-transform: scale(0.6, 0.6);
  transform: scale(0.6, 0.6);
}

html.w-mod-js *[data-ix='scale-on-scroll'] {
  opacity: 0;
  -webkit-transform: scale(0.01, 0.01);
  -ms-transform: scale(0.01, 0.01);
  transform: scale(0.01, 0.01);
}

@media (max-width: 991px) {
  .navigation-link {
    color: hsla(0, 0%, 100%, 0.52);
  }
  .navigation-link.w--current {
    color: #fff;
  }
  .hamburger-button.w--open {
    background-color: #3b99d9;
  }
  .navigation-menu {
    background-color: #3b99d9;
  }
  .hero-section.centered {
    position: relative;
    top: 50px;
    padding-top: 144px;
    padding-bottom: 144px;
  }
  .grid-image {
    width: 50%;
    padding: 15px;
  }
}

@media (max-width: 767px) {
  .button.full-width {
    margin-right: auto;
    margin-left: auto;
  }
  .button.tab {
    font-size: 12px;
  }
  .white-box {
    margin-bottom: 30px;
  }
  .hero-section.centered {
    padding-top: 119px;
    padding-bottom: 119px;
  }
  .hero-heading {
    margin-bottom: 15px;
    font-size: 50px;
  }
  .hero-subheading {
    font-size: 18px;
  }
  .form-field.text-area {
    display: block;
  }
  .grid-image {
    width: 20%;
  }
  .spc {
    margin-bottom: 30px;
  }
}

@media (max-width: 479px) {
  .button {
    margin-bottom: 25px;
  }
  .hero-section.centered {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .section-subheading.center {
    line-height: 30px;
  }
  .grid-image {
    width: 35%;
  }
}

.nav-wrapper {
  display: flex;
  text-align: center;
}

.card-title {
  text-transform: none;
  letter-spacing: 1px;
  font-size: 26px;
  font-weight: 400;
  word-break: break-word;
}

.product-card-title {
  font-family: 'Satisfy', cursive;
  text-transform: none;
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 25px;
  color: #e08d06;
  margin-top: 0;
}
a {
  color: #e08d06;
  word-break: break-all;
}

.custom-subheading {
  letter-spacing: 0px !important;
  font-size: 16px;
}

p {
  font-size: 16px;
}

.italic {
  font-style: italic;
  color: #e08d06;
}

.left {
  text-align: left !important;
}

.card-icon {
  width: 33px;
  color: white;
}

.footer-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  background: #8e8e9c;
  color: white;
  font-size: 10px;
  font-weight: 300;
}

.footer-banner h6 {
  font-weight: 300;
}
